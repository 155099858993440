let host = window.location.host.replace("www.", "");

const accounts: any = {
  "dev.bob.co.za": {
    title: "Bob API :: Dev",
    api: "api.dev.bob.co.za",
    raygunKey: "7FfQUY9tLIbckIwfvJF4g"
  },
  "manage.dev.bobbox.co.za": {
    title: "Bob Box :: Management Dev",
    api: "api.dev.bob.co.za",
    raygunKey: "7FfQUY9tLIbckIwfvJF4g"
  },
  "counter.dev.bobbox.co.za": {
    title: "Bob Box :: Counter Dev",
    api: "api.dev.bob.co.za",
    raygunKey: "7FfQUY9tLIbckIwfvJF4g"
  },
  "manage.bobbox.co.za": {
    title: "Bob Box :: Management",
    api: "api.bob.co.za",
    raygunKey: "7FfQUY9tLIbckIwfvJF4g"
  },
  "counter.bobbox.co.za": {
    title: "Bob Box :: Counter",
    api: "api.bob.co.za",
    raygunKey: "7FfQUY9tLIbckIwfvJF4g"
  },
  "my.bob.co.za": {
    title: "Bob API",
    api: "api.bob.co.za",
    raygunKey: "epVfqXxvMDx8paYEpF6Kw"
  }
};

if (!accounts[host]) {
  host = "dev.bob.co.za";
}

const account = accounts[host];

export default {
  accounts,
  region: "af-south-1",
  ...account
};
