import * as roleUtils from "utils/roleUtils";
import { Fragment, useEffect } from "react";
import { IAccount } from "interfaces/account.interface";
import { IUser } from "interfaces/user.interface";
// @ts-ignore
import { Link, withRouter } from "react-router-dom";

import { NavItem, NavItemDivider, navUtils, interfaces, withStore } from "bob-group-ui-framework";
import { withErrorBoundary } from "errorBoundary";

interface IProps {
  isNavbarCollapsed: boolean;
  closeMenu: any;
  history: any;
  store: any;
  activeUser: IUser;
  onMounted: () => void;
}

function AccountsNavigation(props: IProps) {
  const { isNavbarCollapsed, closeMenu, store, activeUser } = props;

  useEffect(() => {
    props.onMounted();
  }, []);

  /* -------------------------------- */
  /* RENDER METHODS */
  /* -------------------------------- */

  function renderNavSectionItems(section: any) {
    return section.map((item: any, itemIndex: number) => {
      return (
        <Fragment key={itemIndex}>
          {item.hasAccess && item.displayCondition && (
            <Link to={item.path} onClick={() => closeMenu()}>
              <NavItem item={item} isNavbarCollapsed={isNavbarCollapsed} />
            </Link>
          )}
        </Fragment>
      );
    });
  }

  function renderNavSections(_: IAccount, activeUser: IUser) {
    const navSections: interfaces.INavItem[][] = [
      [
        {
          displayName: "My profile",
          icon: "user",
          path: "/profile",
          hasAccess: true,
          displayCondition: true
        },
        {
          displayName: "Account",
          icon: "star",
          path: "/account",
          hasAccess: roleUtils.isAccountSuperUser(activeUser),
          displayCondition: Boolean(activeUser.account_id)
        }
      ],
      [
        {
          displayName: "Settings",
          icon: "cog",
          path: "/settings",
          hasAccess: roleUtils.isAccountSuperUser(activeUser),
          displayCondition: Boolean(activeUser.account_id)
        }
      ]
    ];

    return navSections.map((section: any, sectionIndex: number) => {
      const displayBreak =
        navUtils.checkIfUserHasAccessToNavSection(section) &&
        navUtils.checkIfUserHasAccessToNavSection(navSections[sectionIndex + 1]); // If user has access to both current and next section, add <NavItemDivider /> element
      return (
        <Fragment key={sectionIndex}>
          {renderNavSectionItems(section)}
          {displayBreak ? <NavItemDivider /> : null}
        </Fragment>
      );
    });
  }

  function render() {
    if (!store) return null;

    const account = store.account;

    if (activeUser) {
      return renderNavSections(account, activeUser);
    }

    const loginItem: interfaces.INavItem = {
      path: "/login",
      displayName: "Log in",
      icon: "sign-in-alt"
    };

    // Not logged in
    return (
      <>
        <Link to="/login" onClick={() => closeMenu()}>
          {/* @ts-ignore */}
          <NavItem item={loginItem} isNavbarCollapsed={isNavbarCollapsed} />
        </Link>
      </>
    );
  }

  return render();
}
// @ts-ignore
export default withStore(withRouter(withErrorBoundary(AccountsNavigation)));
