function ErrorState() {
  return (
    <div className="rounded-md bg-red-light text-red p-4 shadow-lg m-4">
      <div className="space-y-4">
        <div className="font-semibold">Oops, something went wrong!</div>
        <div>
          Please try refreshing your page. If the error persists please contact your system
          administrator.
        </div>
      </div>
    </div>
  );
}

export default ErrorState;
