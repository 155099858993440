import "./AccountClosedPage.scss";
import { Button, Card, PageHeading, useStore, withStore } from "bob-group-ui-framework";
import { withErrorBoundary } from "errorBoundary";
import * as authUtils from "utils/authUtils";

function AccountClosedPage() {
  const store = useStore();
  const { provider_settings } = store;

  return (
    <div className="account-closed">
      <Card>
        <PageHeading isCenter>
          Your account has been closed. <br />
          Please contact support{" "}
          {provider_settings.support_email_address ? (
            <span>
              at{" "}
              <a href={"mailto:" + provider_settings.support_email_address}>
                {provider_settings.support_email_address}
              </a>
            </span>
          ) : (
            ""
          )}{" "}
          to reopen it.
        </PageHeading>
        <div className="text-center">
          <img src="/account_closed.png" alt="Account closed" />
        </div>

        <div className="text-center mt-30">
          <Button.Primary
            title="Log out"
            onClick={() => {
              authUtils.logOut(store);
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default withStore(withErrorBoundary(AccountClosedPage));
