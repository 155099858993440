import * as utils from "utils/utils";
import { IUser } from "interfaces/user.interface";
import { roleUtils } from "bob-group-ui-framework";

const ROLE_PUBLIC = "Public";
const ROLE_ACCOUNT_USER = "AccountUser";
const ROLE_COUNTER_OPERATOR = "CounterOperator";
const ROLE_BOB_BOX_ADMIN = "BobBoxAdmin";
const ROLE_BOB_BOX_CUSTOMER_SUPPORT = "BobBoxCustomerSupport";
const ROLE_BOB_BOX_HARDWARE_ADMIN = "Bob Box Hardware Admin";
const ROLE_LOCKER_MAINTENANCE = "LockerMaintenance";
const ROLE_ACCOUNT_SUPER_USER = "AccountSuperUser";
const ROLE_SUPER_ADMIN = "SuperAdmin";

const searchBarPermissions = ["API_/accounts:GET", "API_/invoices:GET", "API_/credit-notes:GET"];

const accountSpecificRoles = [
  {
    label: "Account User",
    value: 4
  },
  {
    label: "Account Superuser",
    value: 3
  }
];

function isAccountSuperUser(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser && activeUser.account_id && roleUtils.hasRole(activeUser, [ROLE_ACCOUNT_SUPER_USER])
  );
}

function isAccountUser(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser && activeUser.account_id && roleUtils.hasRole(activeUser, [ROLE_ACCOUNT_USER])
  );
}

function allStaffRoles(roles: any[], activeUser: IUser | undefined): any[] {
  let filteredRoles = roles.filter(
    role =>
      role.name !== ROLE_ACCOUNT_USER &&
      role.name !== ROLE_ACCOUNT_SUPER_USER &&
      role.name !== ROLE_PUBLIC
  );

  if (!isSuperAdmin(activeUser)) {
    filteredRoles = filteredRoles.filter(role => role.value !== ROLE_SUPER_ADMIN);
  }

  return filteredRoles;
}

function isStaff(activeUser: IUser | undefined): boolean {
  return (
    Boolean(activeUser && !activeUser.account_id) && !roleUtils.hasRole(activeUser, [ROLE_PUBLIC])
  );
}

function isSuperAdmin(activeUser: IUser | undefined): boolean {
  return Boolean(activeUser && roleUtils.hasRole(activeUser, [ROLE_SUPER_ADMIN]));
}

function isAccountUserOrSuper(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser &&
      activeUser.account_id &&
      roleUtils.hasRole(activeUser, [ROLE_ACCOUNT_USER, ROLE_ACCOUNT_SUPER_USER])
  );
}

function userHasAdminSystemAccess(props: { store: any }) {
  const { store } = props;
  const { impersonated_user_id } = store;
  const activeUser = utils.activeUserFromStore(store);
  if (!impersonated_user_id && activeUser && !activeUser.account_id && isStaff(activeUser)) {
    return true;
  }
  return false;
}

function isBobBoxCounterOperatorUser(roleName: string): boolean {
  return roleName === ROLE_COUNTER_OPERATOR;
}

function isBobBoxLockerMaintenanceUser(roleName: string): boolean {
  return roleName === ROLE_LOCKER_MAINTENANCE;
}

function isBobBoxAdminUser(roleName: string): boolean {
  return roleName === ROLE_BOB_BOX_ADMIN;
}

function isBobBoxHardwareAdminUser(roleName: string): boolean {
  return roleName === ROLE_BOB_BOX_HARDWARE_ADMIN;
}

function isBobBoxCustomerSupportUser(roleName: string): boolean {
  return roleName === ROLE_BOB_BOX_CUSTOMER_SUPPORT;
}

function isBobBoxUser(activeUser: IUser): boolean {
  if (!activeUser || !activeUser.role) return false;

  const roleName = activeUser.role.name;
  return (
    isBobBoxCounterOperatorUser(roleName) ||
    isBobBoxLockerMaintenanceUser(roleName) ||
    isBobBoxAdminUser(roleName) ||
    isBobBoxCustomerSupportUser(roleName) ||
    isBobBoxHardwareAdminUser(roleName)
  );
}

export {
  isBobBoxCustomerSupportUser,
  isBobBoxAdminUser,
  isBobBoxUser,
  isBobBoxCounterOperatorUser,
  isBobBoxLockerMaintenanceUser,
  isBobBoxHardwareAdminUser,
  allStaffRoles,
  userHasAdminSystemAccess,
  isAccountSuperUser,
  isAccountUser,
  isAccountUserOrSuper,
  isSuperAdmin,
  isStaff,
  accountSpecificRoles,
  searchBarPermissions,
  ROLE_PUBLIC,
  ROLE_ACCOUNT_SUPER_USER,
  ROLE_ACCOUNT_USER,
  ROLE_SUPER_ADMIN
};
