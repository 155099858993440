// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyAyt2_zjlIhSQzzhkd6MvDzqXTdxi4N2kc",
  authDomain: "bobapi-f13bc.firebaseapp.com",
  projectId: "bobapi-f13bc",
  storageBucket: "bobapi-f13bc.appspot.com",
  messagingSenderId: "115732079337",
  appId: "1:115732079337:web:00abadb6a4354da978c678",
  measurementId: "G-PBTXY995SY"
};

const vapidKey =
  "BOlMww7rP7pjnCIYgvbpuOzisBQSoGdpzza-aUgk4ugcOYe2lUTOiaEWeVaEXDGvuNQT__b0YutbcHvls7DXWmw";

interface IProps {
  store: any;
  onPushNotificationTokenSet: (token: string) => void;
}

export default function PushNotifications(props: IProps) {
  const { store } = props;

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    getToken(messaging, {
      vapidKey
    })
      .then((currentToken: any) => {
        props.onPushNotificationTokenSet(currentToken);
      })
      .catch(err => {
        console.log("An error occurred while retrieving token. ", err);
      });

    onMessage(messaging, payload => {
      store.emitter.emit("showToast", {
        text: payload?.notification?.body,
        variant: "success",
        autoHide: 3000
      });
    });
  }, []);

  return null;
}
