import * as utils from "utils/utils";
import { IUser } from "interfaces/user.interface";
import { generalUtils } from "bob-group-ui-framework";

function getAccessToken() {
  return localStorage.getItem("access_token") ?? null;
}

function setAccessToken(token: any) {
  localStorage.setItem("access_token", token);
}

function logOut(store: any) {
  store.emitter.emit("logOut", false);
}

async function logIn(store: any, email: string, password: string, history: any) {
  // Try jwt login
  const jwtLoginResult = await utils.signedRequest(store, "/login", "POST", { email, password });
  if (jwtLoginResult.ok && jwtLoginResult.data.access_token) {
    setAccessToken(jwtLoginResult.data.access_token);
    redirectAfterLogin(history);
    return {
      loginSuccess: true,
      user: jwtLoginResult,
      error: null
    };
  }
  return { loginSuccess: false, user: null, error: generalUtils.getError(jwtLoginResult.error) };
}

async function refreshToken(store: any, activeUser: IUser) {
  if (
    localStorage.getItem("isRefreshing") !== null &&
    // @ts-ignore
    !JSON.parse(localStorage.getItem("isRefreshing"))
  ) {
    localStorage.setItem("isRefreshing", "true");

    const result = await utils.signedRequest(store, "/login/refresh", "POST", {
      user_id: activeUser.id
    });
    localStorage.setItem("isRefreshing", "false");

    if (result.ok && result?.data?.access_token) {
      setAccessToken(result?.data?.access_token);
    }
  }
}

function redirectAfterLogin(history: any) {
  if (history) {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get("redirect");
    if (redirect) {
      history.go(redirect);
    } else if (
      window.location.pathname.indexOf("/login") >= 0 ||
      window.location.pathname.indexOf("/register") >= 0
    ) {
      history.go("/");
    }
  }
}

async function resetPassword(store: any, userID?: any, email?: string) {
  const args: any = {};

  if (userID) {
    args.user_id = userID;
  }

  if (email) {
    args.email = email;
  }

  return await utils.signedRequest(store, "/users/reset-password", "POST", args);
}

async function updatePassword(store: any, password: string, userID?: any) {
  const args: any = { password };

  if (userID) {
    args.user_id = userID;
  }

  return await utils.signedRequest(store, "/users/update-password", "POST", args);
}

async function resendInvitation(store: any, email: any) {
  return await resetPassword(store, undefined, email);
}

async function verifyAccountFromEmailLink(
  store: any,
  verificationCode: string,
  email?: string,
  userID?: number,
  password?: string
) {
  const args: any = {
    verification_code: verificationCode
  };

  if (email) {
    args.email = email;
  }

  if (userID) {
    args.user_id = userID;
  }

  if (password) {
    args.password = password;
  }

  return await utils.signedRequest(store, "/login", "POST", args);
}

async function resetPasswordFromEmailLink(
  store: any,
  verificationCode: string,
  emailOrUsername: string,
  password?: string
) {
  const args: any = {
    verification_code: verificationCode,
    email: emailOrUsername
  };

  if (password) {
    args.password = password;
  }
  return await utils.signedRequest(store, "/login", "POST", args);
}

export {
  logIn,
  logOut,
  getAccessToken,
  refreshToken,
  resetPassword,
  updatePassword,
  resendInvitation,
  verifyAccountFromEmailLink,
  resetPasswordFromEmailLink
};
