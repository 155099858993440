import { withErrorBoundary } from "errorBoundary";
import { Card, PageHeading, SectionHeading } from "bob-group-ui-framework";
import "./MaintenancePage.scss";

interface IProps {
  error: string;
}

function MaintenancePage(props: IProps) {
  const { error } = props;
  return (
    <div className="maintenance">
      <Card>
        <PageHeading isCenter>
          {error ? error : "Hi there, We’re busy with some scheduled maintenance"}
        </PageHeading>
        <div className="text-center">
          <img src="/maintenance.png" alt="Maintenance mode" />
        </div>
        <SectionHeading isCenter>Operations will continue again shortly</SectionHeading>
      </Card>
    </div>
  );
}

export default withErrorBoundary(MaintenancePage);
