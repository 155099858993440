import { generalUtils } from "bob-group-ui-framework";
import { ITradingHours } from "interfaces/tradingHours.interface";

// Copied from google API developer guide
const googleComponentForm: any = {
  street_number: "short_name",
  route: "long_name",
  sublocality_level_1: "long_name",
  sublocality_level_2: "long_name",
  locality: "long_name",
  administrative_area_level_2: "short_name",
  administrative_area_level_1: "long_name",
  country: "short_name",
  postal_code: "short_name"
};

const emptyAddress: any = {
  company: "",
  address: "",
  local_area: "",
  city: "",
  code: "",
  zone: "",
  country: "",
  entered_address: "",
  latitude: 0,
  longitude: 0,
  lat: 0,
  lng: 0
};

function emptyAddressObj() {
  return generalUtils.clone(emptyAddress);
}

const accountTypes: any = [
  {
    label: "Prepaid",
    value: "prepaid"
  },
  {
    label: "Postpaid",
    value: "postpaid"
  }
];

const accountStatuses: any = [
  {
    label: "Closed",
    value: "closed"
  },
  {
    label: "Open",
    value: "open"
  },
  {
    label: "Temporary account",
    value: "temp"
  }
];

const s3Buckets: any = {
  accounts: "accounts"
};

const s3Folders: any = {
  addressBook: "address-books"
};

const absoluteQueryOptions: any = [
  { label: "Specify date range", value: "date_range" },
  { label: "Specify month", value: "month" }
];

const pushNotificationPlatforms = ["web", "android", "ios"];

const locationTypes = [
  { label: "Locker", value: "bob-box-locker" },
  { label: "Counter", value: "bob-box-counter" }
];

const locationStatuses = [
  { label: "Draft", value: "draft" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Maintenance mode", value: "maintenance-mode" }
];

const compartmentStatuses = [
  { label: "Available", value: "available" },
  { label: "Reserved", value: "reserved" },
  { label: "Occupied", value: "occupied" },
  { label: "Expired", value: "expired" },
  { label: "Out of service", value: "out-of-service" }
];

const doorStatuses = [
  { label: "Open", value: "open" },
  { label: "Closed", value: "closed" }
];

const secureCitizenRequestTypes = [
  { label: "Lookup", value: "id-lookup" },
  { label: "Verification", value: "id-verification" }
];

const reservationStatuses = [
  { label: "Waiting for pickup", value: "waiting-for-pickup" },
  { label: "Waiting for dropoff", value: "waiting-for-dropoff" },
  { label: "Complete", value: "complete" },
  { label: "Expired", value: "expired" },
  { label: "Returned", value: "returned" },
  { label: "Cancelled", value: "cancelled" }
];

const compartmentSizes = [
  {
    type: "Small",
    width: 32,
    length: 56,
    height: 12
  },
  {
    type: "Medium",
    width: 32,
    length: 56,
    height: 26
  },
  {
    type: "Large",
    width: 32,
    length: 56,
    height: 53
  }
];

const sageImportBanks = [
  { label: "ABSA", value: "absa" },
  { label: "Nedbank", value: "nedbank" }
];

const lockerCommsMessageTypes = [
  { label: "Reset event", value: "reset-event" },
  { label: "Door events", value: "door-events" },
  { label: "Compartment Status", value: "compartment-status" },
  { label: "Alarm", value: "alarm" },
  { label: "System status", value: "system-status" },
  { label: "Sync up", value: "sync-up" },
  { label: "Maintenance command", value: "maintenance-command" },
  { label: "Sync down", value: "sync-down" },
  { label: "Pins state", value: "pins-state" },
  { label: "Slack", value: "slack" },
  { label: "Logs", value: "logs" }
];

const lockerCommsSourceTypes = [
  { label: "NBIoT", value: "nbiot" },
  { label: "MQTT Sigfox", value: "mqtt-sigfox" },
  { label: "MQTT", value: "mqtt" },
  { label: "Sigfox", value: "sigfox" },
  { label: "Driver app", value: "driver-app" },
  { label: "Maintenance app", value: "maintenance-app" }
];

const lockerConfigurations = [
  { label: "Single cabinet", value: "single" },
  { label: "Primary plus one cabinet", value: "primary_plus_one" },
  { label: "Primary plus two cabinets", value: "primary_plus_two" },
  { label: "Primary plus three cabinets", value: "primary_plus_three" }
];

const googleTrackingId = "G-PBTXY995SY";

const bobBoxLockerMarker = "/locker-marker.png";
const bobBoxCounterMarker = "/counter-marker.png";

const tagConfig: any = {
  location: {
    title: "Location tags",
    url: "/tags",
    typeURL: "location",
    postBody: { type: "bob_box_location" },
    getArgs: { type: "bob_box_location" },
    cacheIdentifier: "location_tags"
  },
  locationNotes: {
    title: "Location note tags",
    url: "/tags",
    typeURL: "location-notes",
    postBody: { type: "bob_box_location_note" },
    getArgs: { type: "bob_box_location_note" },
    cacheIdentifier: "location_note_tags"
  }
};

const daysOfWeek = [
  { label: "Sunday", value: "sunday" as keyof ITradingHours },
  { label: "Monday", value: "monday" as keyof ITradingHours },
  { label: "Tuesday", value: "tuesday" as keyof ITradingHours },
  { label: "Wednesday", value: "wednesday" as keyof ITradingHours },
  { label: "Thursday", value: "thursday" as keyof ITradingHours },
  { label: "Friday", value: "friday" as keyof ITradingHours },
  { label: "Saturday", value: "saturday" as keyof ITradingHours },
  { label: "Public holidays", value: "holidays" as keyof ITradingHours }
];

const googleMapsAPIKey = "AIzaSyDYamglA-r41yH65q7V-sX85hNqHtejYk0";

export {
  googleMapsAPIKey,
  tagConfig,
  pushNotificationPlatforms,
  accountStatuses,
  accountTypes,
  googleComponentForm,
  emptyAddressObj,
  s3Buckets,
  s3Folders,
  absoluteQueryOptions,
  locationTypes,
  locationStatuses,
  compartmentStatuses,
  doorStatuses,
  secureCitizenRequestTypes,
  reservationStatuses,
  compartmentSizes,
  sageImportBanks,
  lockerCommsMessageTypes,
  lockerCommsSourceTypes,
  googleTrackingId,
  bobBoxLockerMarker,
  bobBoxCounterMarker,
  lockerConfigurations,
  daysOfWeek
};
