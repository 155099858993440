import ScrollToTop from "./components/ScrollToTop";
import "react-table-6/react-table.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as ReactDOMClient from "react-dom/client";
import { CompatRouter } from "react-router-dom-v5-compat";

import "bob-group-ui-framework/dist/tailwind.css";
import "bob-group-ui-framework/dist/bob-group-ui-framework.cjs.development.css";

// eslint-disable-next-line no-undef
if ("serviceWorker" in navigator) {
  // eslint-disable-next-line no-undef
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}

// eslint-disable-next-line no-undef
require("./styles/index.scss");

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, "g"), replacement);
};

// eslint-disable-next-line no-extend-native
String.prototype.contains = function contains(needle) {
  return this.indexOf(needle) >= 0;
};

// eslint-disable-next-line no-undef
const container = document.getElementById("root");

const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <CompatRouter>
      <ScrollToTop />
      <App />
    </CompatRouter>
  </BrowserRouter>
);
