import ErrorState from "components/ErrorState";
import { ErrorBoundary } from "react-error-boundary";

export function withErrorBoundary(Component: any) {
  return function WrappedComponent(props: any) {
    return (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={ErrorState} onError={async () => {}}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
}
